<template>
    <div class="footer">
        <div class="title">
            <div @click="$router.push('/home')" class="item">首页</div>
            <div @click="$router.push('/product')" class="item">产品中心</div>
            <div @click="$router.push('/introduce')" class="item">公司介绍</div>
            <div @click="$router.push('/news')" class="item">新闻中心</div>
            <div @click="$router.push('/contact')" class="item" style="border-right: 0px solid;">联系我们</div>
        </div>
        <div>地址：浙江省台州市椒江区台州维多利亚整形美容医院(浙南总院)</div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer {
    width: 100%;
    height: 170px;
    background-color: rgb(248, 248, 248);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .title {
        width: 80%;
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .item {
            width: 160px;
            border-right: 1px solid #ccc;
            height: 20px;
            text-align: center;

            &:hover {
                color: #3388FF;
                cursor: pointer;
            }
        }

    }
}
</style>