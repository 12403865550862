<template>
    <div>
        <div class="header">
            <div class="logo">
                <img src="@/assets/logo.png" alt="">
                <span>台州维多利亚医疗美容医院</span>
            </div>
            <div class="menu">
                <el-menu :router="true" :default-active="activeIndex" :defaultActive="activeIndex" text-color="#000"
                    class="el-menu-demo" mode="horizontal" @select="handleSelect">
                    <el-menu-item index="/home">首页</el-menu-item>
                    <el-menu-item index="/product">产品中心</el-menu-item>
                    <el-menu-item index="/introduce">公司介绍</el-menu-item>
                    <el-menu-item index="/news">新闻中心</el-menu-item>
                    <el-menu-item index="/contact">联系我们</el-menu-item>

                </el-menu>
                <el-button type="primary" @click="onKST()"> 免费咨询</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'is_Header',
    data() {
        return {
            activeIndex: ''
        }
    },
    mounted() {
        this.activeIndex = window.location.href.split('#')[1];
    },
    methods: {
        handleSelect() { },

        onKST() {
            this.$bus.$emit('onkst')
        }




    }
}
</script>

<style lang="scss" scoped>
.header {

    ::v-deep {
        .menu {
            width: 600px;
            display: flex;
            align-items: center;

            .el-menu-demo {
                width: 500px;
                border-bottom: 0px solid;


            }
        }
    }

    width: 60%;
    height: 100px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    .logo {
        display: flex;
        align-items: center;

        img {
            width: 60px;
            height: 60px;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
</style>