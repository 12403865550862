import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import elementui from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/style.scss";

Vue.use(elementui);

Vue.config.productionTip = false;

new Vue({
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  render: (h) => h(App),
  router,
}).$mount("#app");
