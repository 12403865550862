import vueRouter from "vue-router";
import Vue from "vue";
Vue.use(vueRouter);
const routes = [
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/home",
    component: () => import("@/views/index"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/product",
    component: () => import("@/views/product"),
    meta: {
      title: "产品中心",
    },
  },
  {
    path: "/introduce",
    component: () => import("@/views/introduce"),
    meta: {
      title: "公司介绍",
    },
  },
  {
    path: "/news",
    component: () => import("@/views/news"),
    meta: {
      title: "新闻中心",
    },
  },
  {
    path: "/contact",
    component: () => import("@/views/contact"),
    meta: {
      title: "联系我们",
    },
  },
  {
    path: "/index",
    component: () => import("@/views/index"),
  },
];
const router = new vueRouter({
  routes,
});

router.afterEach((to, from) => {
  document.title = "台州维多利亚-" + to.meta.title; //在全局后置守卫中获取路由元信息设置title
});

export default router;
