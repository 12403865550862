<template>
  <div id="app">
    <Header></Header>
    <router-view>
    </router-view>
    <footers></footers>
  </div>
</template>

<script>

import Header from './views/header'
import footers from './views/footer'
export default {
  name: 'App',
  components: { Header, footers },
  mounted() {
    this.$bus.$on('onkst', (msg) => {

      const link = document.createElement('a')
      link.href = 'https://viph19-shtk15.kuaishang.cn/bs/im.htm?cas=113412___122629&fi=115392'
      link.click()
    })
  }

}


</script>

<style>
</style>
